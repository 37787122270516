import type { JSX } from 'react';
import { Testimonials } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type TestimonialsProps = SliceComponentProps<Content.TestimonialsSlice>;

const TestimonialsSlice = ({ slice }: TestimonialsProps): JSX.Element => {
  return <Testimonials {...slice} />;
};

export default TestimonialsSlice;
